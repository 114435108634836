<template>
  <div>
    <keep-alive include="index,contact,mindex,head,mcate,mProductList">
      <router-view v-wechat-title="$route.meta.title"> </router-view>
    </keep-alive>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";

export default {
  metaInfo() {
    return {
      title: this.webForm.websiteName,
      meta: [
        { name: "description", content: this.webForm.description },
        { name: "keywords", content: this.webForm.keywords },
        { property: "og:title", content: this.webForm.websiteName },
      ],
      link: [
        { rel: "icon", type: "image/x-icon", href: this.webForm.icons }, // 添加网站图标
      ],
    };
  },
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      webForm: {
        description: undefined,
        keywords: undefined,
        websiteName: undefined,
        websiteShortName: undefined,
        icons: undefined,
      },
      isRouterAlive: true,
      queryParam: {},
    };
  },
  components: {},
  computed: {
    ...mapState({
      //查询网站信息配置
      webSetting: (state) => state.index.webSetting,
      //查询网站系统配置
      setting: (state) => state.index.setting,
    }),
  },

  mounted() {
    this.$router.beforeEach((to, from, next) => {
      // 在路由导航前更新网站名称
      const siteName = to.meta.siteName || "首页";
      this.siteName = siteName;
      next();
    });

    this.reload();
  },
  methods: {
    reload() {
      //查询网站基本信息
      this.$store.dispatch("index/getWebSetting", this.queryParam).then(() => {
        this.webForm.websiteName = this.webSetting.detail.websiteName;
        this.webForm.description = this.webSetting.detail.description;
        this.webForm.keywords = this.webSetting.detail.keywords;
        this.webForm.icons = this.webSetting.detail.icons;
        this.webForm.websiteShortName = this.webSetting.detail.websiteShortName;

        Vue.prototype.$globalVar = this.webSetting.detail.websiteName;

        //接入代码
        if (this.webSetting.detail.scriptCode) {
          this.loadScript(this.webSetting.detail.scriptCode);
        }
      });

      //查询网站系统设置
      this.$store.dispatch("index/getSetting", this.queryParam).then(() => {
        localStorage.setItem("isCardAuth", this.setting.isCardAuth);
        localStorage.setItem("cardAuthTip", this.setting.cardAuthTip);
      });
    },

    loadScript(scriptUrl) {
      scriptUrl = scriptUrl.replace(/<script>|<\/script>/g, "");

      let script = document.createElement("script");
      script.type = "text/javascript";
      script.textContent = scriptUrl;
      document.body.appendChild(script);
    },
  },
};
</script>

<style>
body {
  padding: 0px;
  margin: 0px;
  background-color: #f5f7fa;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 20px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  overflow-y: scroll;
}
.el-loading-spinner {
  font-size: 12px;
}
/* img {
  width: 100%;
  height: auto;
  max-width: 100%;
} */

/* .el-menu-item {
  margin: 0px 6px !important;
} */

/* .el-container {
  flex-direction: column;
} */
/* .el-main { */
/* background: #f8f8f8; */
/* min-height: 800px; */
/* padding: 0px; */
/* } */
.el-form-item__label {
  color: #5f5f5f;
  font-size: 14px;
  font-family: PingFang SC;
  padding-bottom: 2px !important;
}
/* .el-form-item {
  margin-bottom: 26px;
} */
.el-breadcrumb {
  font-size: 16px;
}
/* .el-tabs__nav-scroll {
  overflow: hidden;
  background: #fff;
  padding: 0px 40px;
  box-sizing: border-box;
  width: 100%;
} */
.el-tabs__nav-wrap::after {
  background: #fff;
}
/* .el-tabs__nav {
  padding: 2px 0px;
} */
/* .el-tabs__item {
  font-size: 16px;
} */
.el-loading-spinner i {
  color: #9fa1a8;
  font-size: 60px;
}
a {
  text-decoration: none;
}
.el-table th.is-leaf {
  background: #f8f8f8;
}

/* PC端样式 */
.pc-common {
  width: 1300px;
  margin: 0 auto;
}

.module-common {
  margin-top: 10px;
  position: relative;
}

/* PC端登录注册样式 */

.login {
  width: 100%;
  height: 100vh;
  background: rgba(51, 51, 51, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.login .login-box {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(180deg, #dff4fe, #fff);
  background-size: cover;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 500px;
  padding-bottom: 50px;
  /* height: 588px; */
}

.login .login-box:after {
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background-image: url(./assets/image/login_img_bg.7ca96f62.png);
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1;
}

.login .login-box .el-icon-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #525252;
  z-index: 2;
  cursor: pointer;
}

.login .login-box .el-icon-close:hover {
  transform: scale(110%);
}

.login .login-box .login-box-content {
  background: #fff;
  box-shadow: 0 6px 16px 0 #eaf7ff;
  border-radius: 15px 15px 15px 15px;
  z-index: 2;
  width: 454px;
  margin-top: 90px;
  /* height: 466px; */
}

.login .login-box .login-box-content .type-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e7ee;
  height: 77px;
  padding: 0 80px;
}

.login .login-box .login-box-content .type-title span.item {
  font-weight: 400;
  color: #333;
  cursor: pointer;
  font-size: 18px;
}

.login .login-box .login-box-content .type-title span.active {
  font-weight: 500;
  position: relative;
}

.login .login-box .login-box-content .type-title span.active:after {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 4px;
  background: #209bff;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  bottom: -28px;
}

.login .findPass:before {
  display: block;
  white-space: nowrap;
  content: "456456";
  font-weight: 550;
  color: #333;
  font-size: 26px;
  position: absolute;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
}

.loginCode {
  width: 100%;
  padding: 1px 40px 10px 40px;
}

.loginCode .login-form {
  margin-top: 20px;
}

.loginCode .login-form .form-item {
  margin-top: 20px;
  width: 354px;
  height: 55px;
  background: #edf1f5;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  padding: 0 19px;
  position: relative;
}

.loginCode .login-form .form-item input {
  outline: none;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  font-size: 14px;
}

.loginCode .login-form .form-item .position {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

.loginCode .login-form .form-item .send-code {
  color: #209bff;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.loginCode .login-form .form-item .count-down {
  color: #acb2b8;
  cursor: default;
}

.loginCode .login-form .form-agreement {
  /* margin-top: 10px; */
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #333;
  font-size: 14px;
}

.loginCode .login-form .form-agreement .spanb {
  font-weight: 400;
  color: #209bff;
  margin: 0 2px;
  cursor: pointer;
}

.loginCode .login-form .form-agreement .choose {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #d0d5da;
  margin-right: 2px;
  cursor: pointer;
}

.loginCode .login-form .form-agreement .active {
  /* background-image: url(../../../../../../static/img/chooseIn.f7e4b357.svg); */
  background-size: cover;
  border: none;
}

.loginCode .login-form .submit-btn {
  margin-top: 18px;
  width: 298px;
  height: 48px;
  background: #209bff;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.loginCode .login-form .loading {
  background: rgba(32, 155, 255, 0.5);
  cursor: wait;
}

.loginCode .login-form .other-login {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.loginCode .login-form .other-login .other-login-title {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  margin-top: 12px;
}

.loginCode .login-form .other-login .other-login-title:after {
  content: "";
  width: 112px;
  height: 2px;
  background: #d0d5da;
  position: absolute;
  top: 50%;
  right: 90px;
  transform: translateY(-50%);
  display: block;
}

.loginCode .login-form .other-login .other-login-title:before {
  position: absolute;
  display: block;
  content: "";
  width: 112px;
  height: 2px;
  background: #d0d5da;
  top: 50%;
  left: 90px;
  transform: translateY(-50%);
}

.loginCode .login-form .other-login .other-login-content {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginCode .login-form .other-login .other-login-content img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.loginCode .login-form .other-login .other-login-content img:hover {
  transform: scale(110%);
}

.loginCode .login-form .other-login .other-login-content img:first-child {
  margin-right: 27px;
}

.loginCode .login-form .other-login .other-login-content img:last-child {
  margin-left: 27px;
}

.page-header {
  padding: 10px 0px;
  font-size: 18px;
  line-height: 26px;
  /* border-bottom: 1px solid #f2f3f5; */
  font-weight: 700;
  color: #333;
  /* margin-bottom: 20px; */
}
.page-header-title:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 24px;
  background: #006eff;
  -webkit-box-shadow: 3px 0 10px -1px #13adff;
  box-shadow: 3px 0 10px -1px #13adff;
  border-radius: 2px;
}

.el_dialog_radius .el-dialog {
  border-radius: 8px;
}

.success-dialog-container {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
}

.success-dialog-container .title {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.success-dialog-container .cont {
  display: flex;
  flex-direction: column;
  color: #868e9b;
  text-align: center;
  font-size: 14px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 14px;
}

.success-dialog-container .footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-dialog-container .footer .but {
  width: 150px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #fd8c6f;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 22px;
  margin-top: 40px;
}

.success-dialog-container .fail {
  width: 55px;
  height: 55px;
  position: absolute;
  top: -25px;
}

.success-dialog-container .dilalog_img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.success-dialog .el-dialog__body {
  padding: 20px 10px;
}

.success-dialog.el-dialog {
  --el-dialog-bg-color: transparent !important;
}

.success-dialog.el-dialog .el-dialog__header,
.success-dialog.el-dialog .el-dialog__body {
  padding: 0;
}

/* 验证码CSS */
.captcha_intelli-control {
  position: relative;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #e4e7eb;
  background-color: #f7f9fa;
  overflow: hidden;
  outline: none;
  margin: 20px 0px;
}

/* 鼠标悬停时的样式 */
.captcha_intelli-control:hover .captcha_intelli-text {
  color: #2065fc;
  cursor: pointer;
}

.captcha_intelli-tips {
  text-align: center;
  color: #45494c;
}

.captcha_intelli-icon {
  position: relative;
  margin-right: 7px;
  width: 28px;
  height: 28px;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 8px 1px rgba(188, 196, 204, 0.5);
  transition: all 0.2s linear;
}

.captcha_intelli-icon .captcha_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  width: 15px;
  height: 17px;
  background-image: url(https://cstaticdun.126.net/2.27.0/images/icon_light.4e88fb8.png);
  background-position: 0 -207px;
  background-size: 40px 1518px;
}

.captcha_intelli-text {
  line-height: 38px;
  vertical-align: middle;
  transition: all 0.2s linear;
}

/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
}

.table-operations {
  margin-bottom: 16px;
}

.table-operations > button {
  margin-right: 8px;
  margin-top: 8px;
  margin-left: 10px;
}
</style>
